<template>
  <div>
    <v-row>
      <v-col>
        <v-btn @click="Insert()">เพิ่ม</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :items="desserts" class="elevation-1">
          <template v-slot:item.action="{ item }">
            <v-btn fab dark small color="lime" @click="Edit(item)">
              <v-icon dark>fa fa-pen</v-icon>
            </v-btn>
            <v-btn fab dark small color="red" @click="Delete(item)">
              <v-icon dark>fa fa-trash-alt</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      headers: [
        {
          text: "ประเภท",
          align: "left",
          sortable: true,
          value: "UserLevelText.TH"
        },
        {
          text: "ชื่อพนักงาน",
          align: "left",
          sortable: true,
          value: "UserName"
        },
        {
          text: "เบอร์โทรศัพท์",
          align: "left",
          sortable: true,
          value: "UserTel"
        },
        {
          text: "หมายเหตุ",
          align: "left",
          sortable: true,
          value: "UserRemark"
        },
        { width: 120, text: "", value: "action", sortable: false }
      ],
      desserts: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.Select();
    });
  },
  methods: {
    Select() {
      axios.get("/api/v1/select/SelectByLevel/user?Level=E").then(res => {
        if (res.status == 200) {
          this.desserts = res.data;
        }
      });
    },
    Edit(v) {
      this.PassAccept("update", () => {
        this.$router.push("/Employee/Add?UserId=" + v.UserId);
      });
    },
    Delete(v) {
      this.PassAccept("delete", () => {
        axios
          .delete("/api/v1/delete/delete/user?UserId=" + v.UserId)
          .then(res => {
            if (res.status == 200) {
              this.Select();
            }
          });
      });
    },
    Insert() {
      this.$router.push("/Employee/Add");
    }
  }
};
</script>
<style></style>
